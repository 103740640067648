import { Formik, Form } from "formik";
import * as Yup from "yup";
import { campaignService } from "services";
import { useState } from "react";
import { toastSuccess } from "helpers/toastHelper";
import "./styles.scss";

type Props = {
  pressReleaseId?: string | number;
  onHide: (item?: any) => void;
};

type InitialState = {
  comments: string;
};

const initialState: InitialState = {
  comments: "",
};

const AddComments = ({
  pressReleaseId,
  onHide = () => null,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const addCommentSchema = Yup.object().shape({
    comments: Yup.string().required("Comments is required"),
  });

  const onAddComment = async (text = "") => {
    try {
      if (!!pressReleaseId) {
        setLoading(true);
        let payload: any = {
          pressReleaseId: pressReleaseId,
        };
        if (text) payload["comment"] = text;
        const response = await campaignService.createComments(payload);
        if (response?.status === 200) {
          toastSuccess(response.message || "Comment added successfully.");
          onHide();
        }
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="commented-input">
        <div className="form-group">
          <Formik
            initialValues={initialState}
            validationSchema={addCommentSchema}
            onSubmit={async (values, { resetForm }) => {
              await onAddComment(values?.comments);
              resetForm();
            }}
          >
            {(formik) => {
              const { values, handleChange, handleSubmit, errors, touched, resetForm } =
                formik;
              return (
                <Form className="form" onSubmit={handleSubmit}>
                  <textarea
                    className="form-control"
                    placeholder="Type your message here"
                    name="comments"
                    value={values.comments}
                    onChange={handleChange}
                    rows={5}
                    cols={40}
                  />
                  {(!!errors.comments && touched.comments) && <span className="inputError">{errors.comments}</span>}
                  <br />
                  <input
                    type="submit"
                    className="form-control comment_btn"
                    value={loading ? "Saving..." : "Comment"}
                    disabled={loading || !values.comments}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddComments;
