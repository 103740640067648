import { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import { authService, campaignService, orderService, paymentService } from "services";
import { Auth } from "types/Auth";
import { useDispatch } from "react-redux";
import { selectIsTabView, setAppState } from "redux/features/appStateSlice";
import { CampaignProps } from "types/users/Campaign";
import {
  getDateFromNow,
  getFormatedDateWithTime,
  getImageLink,
  textCapitalize,
} from "helpers/common";
import AddComments from "components/modal/common/add-comments";
import assets, { CloseGreyIcon, UserProfile } from "assets";
import { Loader } from "components";
import { ParamType, PaymentStatus } from "types/Others";
import { Button, Image, Overlay } from "react-bootstrap";
import { useTypedSelector } from "redux/store";

type TabProps = {
  title: string;
  url: string;
};

const PressReleasePdf = () => {
  const [comments, setComments] = useState<CampaignProps.PRCommentsDetail[]>(
    []
  );
  const [pressRelease, setPressRelease] =
    useState<CampaignProps.PressReleaseDetail | null>(null);
  const [user, setUser] = useState<Auth.UserProfile | null>(null);
  const [loading, setLoding] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const [webUrl] = useState(
    process.env.REACT_APP_WEB_SITE_URL || "http://healthpresso-wp.otherideas.in"
  );
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const isMobileView = useTypedSelector(selectIsTabView);
  const year = new Date()?.getFullYear();

  useEffect(() => {
    const hash = searchParams.get("hash");
    if (!!hash) {
      getUserFromHash(hash);
    }
  }, []);

  const getUserFromHash = async (hash: string) => {
    try {
      setLoding(true);
      const payload: Auth.GenerateTemporaryTokenReq = {
        hash,
      };
      const isDraft = searchParams.get("isDraft");
      console.log()
      const response = await authService.generateTemporaryToken(payload);
      if (response?.success) {
        dispatch(
          setAppState({
            token: response?.token,
            user: response?.data?.userData,
            isRememberMe: false,
            isPublicLink: true,
          })
        );
        setUser(response?.data?.userData);
        if (response?.data?.hashedData?.referenceId) {
          if(isDraft==="false")
          {setTimeout(() => {
            getPressReleaseBySubCampaignId(
              response?.data?.hashedData?.referenceId
            );
          }, 200);}
          else{
            setTimeout(() => {
              getWhatsAppPressRelease(
                response?.data?.hashedData?.referenceId
              );
            }, 200);
          }
        } else setLoding(false);
      }
    } catch (e: any) {
      setLoding(false);
    }
  };

  const getPressReleaseBySubCampaignId = async (id: any) => {
    try {
      if (!!id) {
        let payload: ParamType = {
          filter: {
            pressReleaseId: id,
          },
          range: {
            page: 1,
            pageSize: 1,
          },
        };
        const response = await campaignService.getPressRelease(payload);
        if (response) {
          let newData = response?.data[0]
          if (newData.aiResponse && typeof newData.aiResponse === "string") {
            newData.aiResponse = JSON.parse(newData.aiResponse)
          }
          setPressRelease(newData);
          getCommentsHandler(response?.data[0]?.pressReleaseId);
        }
        setLoding(false);
      }
    } catch {
      setLoding(false);
    }
  };
  const getWhatsAppPressRelease = async (id: any) => {
    try {
      if (!!id) {
        let payload:any = {
          filter: {
            whatsAppPressReleaseId: id,
          },
          range: {
            page: 1,
            pageSize: 1,
          },
        };
        const response = await campaignService.getWhatsappPressRelease(payload);
        if (response) {
          let newData = response?.data[0]
          if (newData.aiResponse && typeof newData.aiResponse === "string") {
            newData.aiResponse = JSON.parse(newData.aiResponse)
          }
          setPressRelease(newData);
          getCommentsHandler(response?.data[0]?.pressReleaseId);
        }
        setLoding(false);
      }
    } catch {
      setLoding(false);
    }
  };

  const getCommentsHandler = async (id: number | undefined) => {
    try {
      if (!!id) {
        let payload = {
          filter: {
            pressReleaseId: id,
          },
          range: {
            page: 1,
            pageSize: 100,
          },
        };
        const response = await campaignService.getComments(payload);
        if (response.status === 200 && !!response?.data) {
          setComments(response?.data);
        }
      }
    } catch {}
  };

  const tabs: TabProps[] = [
    {
      title: "Story",
      url: webUrl,
    },
    {
      title: "Services",
      url: `${webUrl}/services/`,
    },
    {
      title: "Pricing",
      url: `${webUrl}/#pricing-plan`,
    },
    {
      title: "The ChangeMakers",
      url:
        process.env?.REACT_APP_CHANGE_MAKERS_CLUB ||
        "https://healfoundation.in/changemakersclub/",
    },
    {
      title: "lx Academy",
      url: `${webUrl}/lx-academy/`,
    },
    {
      title: "Insights",
      url: `${webUrl}/insights/`,
    },
  ];

  const renderTabs = () => {
    return (
      <>
        <ul className="navbar-nav me-auto mb-2 mb-lg-0 left-menubar">
          {tabs?.map((tab: TabProps, index: number) => (
            <li className="nav-item" key={tab.title}>
              <a
                className={`nav-link ${index === 0 ? "active" : ""}`}
                href={tab.url}
                target="_blank"
              >
                {tab.title}
              </a>
            </li>
          ))}
        </ul>
        <ul className="navbar-nav right-menubar mb-2 mb-lg-0">
          <li className="nav-item">
            <a
              className="nav-link quote-btn"
              href="/login"
              data-bs-toggle="modal"
              data-bs-target="#signupModal"
            >
              <span>
                <img src={UserProfile} alt="Sign In" className="img-fluid" />
              </span>
              Sign In
            </a>
          </li>
        </ul>
      </>
    );
  };

  const pressReleaseContent = pressRelease?.content?.split("\n") ?? [];
  const pressReleaseSummary = pressRelease?.aiResponse?.summary?.split("\n") ?? [];
  const pressReleaseReferences = pressRelease?.aiResponse?.references?.split("\n") ?? [];

  async function handlePressRelasePayment(){
    if(!pressRelease) return null;
    console.log(pressRelease)
    const paymentResponse=await paymentService.generateIframeLink(
      {
        pressReleaseId:pressRelease.pressReleaseId,
        orderId:pressRelease.orderId
      }
    )
    var link = document.createElement('a');
    link.href = paymentResponse.data.paymentIframeLink;
    link.target = '_self';
    link.rel = 'noopener noreferrer'; // For security reasons
    link.click();
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a
            className="navbar-brand"
            href={webUrl}
            title="The Health Presso"
            target="_blank"
          >
            <img
              src={assets.images.webLogo}
              alt="The Health Presso"
              className="img-fluid"
            />
          </a>
          {!isMobileView ? (
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {renderTabs()}
            </div>
          ) : (
            <Overlay target={target.current} show={show} placement="bottom">
              {({
                placement: _placement,
                arrowProps: _arrowProps,
                show: _show,
                popper: _popper,
                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                ...props
              }) => (
                <div
                  {...props}
                  style={{
                    position: "absolute",
                    backgroundColor: "white",
                    padding: "2px 10px",
                    color: "white",
                    borderRadius: 3,
                    zIndex: 30,
                    marginTop: 10,
                    width: "100%",
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    ...props.style,
                  }}
                >
                  {renderTabs()}
                </div>
              )}
            </Overlay>
          )}
        </div>
      </nav>
      {!loading && !!pressRelease ? (
        <div className="press_release_link">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-lg-9">
                <div className="left">
                  <img
                    src={getImageLink(
                      pressRelease?.pressReleaseMedia ,
                      pressRelease?.imageLink ?? "https://placehold.co/1500x500"
                    )}
                    alt="Image"
                    className="pressReleaseImg"
                  />
                  {!!pressRelease?.headline && (
                    <h1>{pressRelease?.headline}</h1>
                  )}
                  {(pressRelease?.aiResponse?.subheading ?? "").trim() !== "" && (
                    <h3>{pressRelease?.aiResponse?.subheading}</h3>
                  )}
                  {!!pressRelease?.updatedAt && (
                    <h6>
                      PR | Updated:{" "}
                      {getFormatedDateWithTime(pressRelease?.updatedAt)}
                    </h6>
                  )}
                  
                  {(pressRelease?.aiResponse?.summary ?? "").trim() !== "" && (
                    <>
                      {pressReleaseSummary
                        .map((t: string, key: number) => {
                          return (
                            <>
                              <p key={`key_${key}`}>{t}</p>
                            </>
                          );
                        })}
                    </>
                  )}
                  
                  <h6>Article:</h6>
                  {pressReleaseContent
                    .map((t: string, key: number) => {
                      return (
                        <>
                          <p key={`key_${key}`}>{t}</p>
                        </>
                      );
                    })}
                  
                  {(pressRelease?.aiResponse?.references ?? "").trim() !== "" && (
                    <>
                      <h6>References:</h6>
                      <ul>
                      {pressReleaseReferences
                        .map((t: string, key: number) => {
                          return (
                            <li key={`key_${key}`}>{t}</li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  
                  {pressRelease?.createdByDetails?.qualification && (
                    <div className="d-flex justify-content-between align-items-center">
                      <Image
                        src={pressRelease?.createdByDetails?.profilePhoto}
                        roundedCircle={false}
                        width={80}
                        className="userProfileImg br5"
                      />
                      <div className="text-end width30p">
                        <span className="client-name">
                          {textCapitalize(
                            pressRelease?.createdByDetails?.fullName
                          )}
                        </span>
                        <br />
                        {textCapitalize(
                          pressRelease?.createdByDetails?.qualification
                        )}
                      </div>
                    </div>
                  )}
                  {!!pressRelease?.keywords?.length && (
                    <div className="tags">
                      <h4>Keywords</h4>
                      {pressRelease?.keywords?.map((keyword) => {
                        return (
                          <a href="#" key={keyword}>
                            {keyword}
                          </a>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              {(!pressRelease?.isPRForEvent && !pressRelease?.isWhatsappPR) && (
                <div className="col-sm-12 col-lg-3">
                  <div className="right">
                    {!!pressRelease?.pressReleaseId && (
                      <AddComments
                        pressReleaseId={pressRelease?.pressReleaseId}
                        onHide={() => {
                          getCommentsHandler(pressRelease?.pressReleaseId);
                        }}
                      />
                    )}
                    <div className="comment-section">
                      {!!comments?.length &&
                        comments?.map(
                          (
                            comment: CampaignProps.PRCommentsDetail,
                            cIndex: number
                          ) => {
                            return (
                              <div
                                className="commented-area"
                                key={comment?.prCommentId}
                              >
                                <img
                                  src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-372-456324.png"
                                  alt="User"
                                />
                                <div className="detail">
                                  <h5>
                                    {comment?.createdBy == user?.userId
                                      ? "You"
                                      : comment?.createdByDetails
                                          ?.fullName}{" "}
                                    <span>
                                      {getDateFromNow(comment?.createdAt)}
                                    </span>
                                  </h5>
                                  <p>{comment?.comment}</p>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : !!loading ? (
        <Loader customClassName="nodata-div" />
      ) : (
        !loading &&
        !pressRelease && (
          <div className="nodata-div text-center">No Press Release Found!</div>
        )
      )}
    </>
  );
};

export default PressReleasePdf;
